<template>
  <div v-if="$config.mqtt.available">
    <v-list-item-action
      v-if="
        !tagesordnungItem.agenda_item.is_paragraph && tagesordnungItem.has_clickable_live_button && meeting.is_current
      "
    >
      <v-tooltip bottom :disabled="mqttConnected">
        <template #activator="{ on, attrs }">
          <v-btn
            :ripple="false"
            icon
            v-bind="attrs"
            x-large
            :color="tagesordnungItem.is_active && mqttConnected ? 'error' : ''"
            :loading="loading"
            v-on="on"
            @click.prevent.stop="toggleActiveTagesordnungItem(tagesordnungItem)"
            ><v-icon>{{ tagesordnungItem.is_active ? 'mdi-play-box' : 'mdi-play-box-outline' }}</v-icon></v-btn
          >
        </template>
        <span>{{ $t('missingMQTTConnection') }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-icon v-else-if="tagesordnungItem.is_active && meeting.is_current" class="ma-0">
      <v-icon color="error">mdi-play-box</v-icon>
    </v-list-item-icon>
  </div>
</template>
<script>
import { api } from '@/api'
import { createLink } from '@/api/helpers'
import { mapState } from 'vuex'
import Profile from '@/store/models/profile'

export default {
  props: {
    meeting: {
      type: Object,
      required: true,
    },
    tagesordnungItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState('application', ['mqttConnected']),
    user() {
      return Profile.query().first()
    },
  },
  methods: {
    async toggleActiveTagesordnungItem() {
      this.loading = true
      try {
        await api.post(createLink('tagesordnung_item/{id}/toggle_activation', { id: this.tagesordnungItem.id }))
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
