import { render, staticRenderFns } from "./TagesordnungList.vue?vue&type=template&id=20c92c63&scoped=true"
import script from "./TagesordnungList.vue?vue&type=script&lang=js"
export * from "./TagesordnungList.vue?vue&type=script&lang=js"
import style0 from "./TagesordnungList.vue?vue&type=style&index=0&id=20c92c63&prod&lang=css"
import style1 from "./TagesordnungList.vue?vue&type=style&index=1&id=20c92c63&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20c92c63",
  null
  
)

export default component.exports