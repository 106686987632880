<template>
  <div>
    <div class="scroll-container">
      <v-btn-toggle v-if="hasMultipleTagesordnungSlots" v-model="selectedDay" tile class="d-flex mb-4 px-4">
        <v-tooltip
          v-for="(day, index) in meeting.tagesordnung_slots"
          :key="day"
          :disabled="tagesordnungByDay[index].items.length > 0"
          top
        >
          <template v-slot:activator="{ on }">
            <div class="grow d-flex" v-on="on">
              <v-btn
                :value="day"
                :disabled="tagesordnungByDay[index].items.length == 0"
                class="grow select-day-button"
                v-bind="selectedDay === day ? { color: 'primary' } : {}"
                :class="{ 'mr-2': index < meeting.tagesordnung_slots.length }"
              >
                <div class="d-flex flex-column">
                  <span>{{ day | toLocaleMonthDayString }}</span>
                  <span class="caption">{{ day | toWeekday($i18n.locale) }}</span>
                </div>
              </v-btn>
            </div>
          </template>
          <span>{{ $t('tagesordnung_days_no_tagesordnung_tooltip_message') }}</span>
        </v-tooltip>
      </v-btn-toggle>
    </div>
    <slot name="top" />
    <div
      v-if="selectedDay"
      :key="tagesordnungForSelectedDay.day"
      :data-testid="`tagesordnung-item-day-${tagesordnungForSelectedDay.day}`"
      class="px-4"
    >
      <h3 class="mb-2" data-testid="tagesordnung-item-day">
        {{ toWeekday(tagesordnungForSelectedDay.day) }} {{ toLocaleDateString(tagesordnungForSelectedDay.day) }}
      </h3>
      <p class="show-line-breaks">{{ meeting.tagesordnung_slot_notes[tagesordnungForSelectedDay.day] }}</p>
      <v-list data-testid="tagesordnung-item-list" two-line subheader>
        <v-list-item
          v-for="tagesordnungItem in tagesordnungForSelectedDay.items"
          :key="tagesordnungItem.id"
          :to="agendaItemLink(tagesordnungItem)"
          color="primary"
          exact
          data-testid="tagesordnung-item"
        >
          <v-list-item-content>
            <v-list-item-title
              data-testid="tagesordnung-item-title"
              :class="{ 'font-weight-bold': tagesordnungItem.agenda_item.is_paragraph }"
            >
              {{ tagesordnungItem.agenda_item.title }}
            </v-list-item-title>
            <v-list-item-subtitle data-testid="tagesordnung-item-notes">
              {{ $t('tagesordnung_notes', { notes: tagesordnungItem.notes || '-' }) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle data-testid="tagesordnung-item-responsible">
              {{ $t('tagesordnung_responsible', { responsible: responsible(tagesordnungItem) }) }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <TagesordnungLiveMeetingButton :meeting="meeting" :tagesordnung-item="tagesordnungItem" />
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>
<script>
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import { toLocaleDateString, toWeekday } from '@/filters'
import TagesordnungItem from '@/store/models/tagesordnungitem'

import size from 'lodash/size'

export default {
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedDay: this.getSelectedDay(),
    }
  },
  computed: {
    tagesordnung() {
      const tagesordnung = TagesordnungItem.query().where('meeting_id', this.meeting.id).orderBy('order').all()
      return tagesordnung
    },
    hasMultipleTagesordnungSlots() {
      return size(this.meeting.tagesordnung_slots) > 1
    },
    tagesordnungByDay() {
      const groups = groupBy(this.tagesordnung, 'day')
      const sortedGroups = orderBy(Object.entries(groups), [0], ['asc'])
      const mappedGroup = sortedGroups.map(([day, items]) => ({ day, items }))
      const tagesordnungsMap = this.meeting.tagesordnung_slots.map((day) => {
        const dayWithItems = mappedGroup.find((tagesordnung) => tagesordnung.day === day)
        return {
          day,
          items: dayWithItems ? dayWithItems.items : [],
        }
      })
      return tagesordnungsMap
    },
    tagesordnungForSelectedDay() {
      const tagesordnungForSelectedDay = TagesordnungItem.query()
        .where('meeting_id', this.meeting.id)
        .where('day', this.selectedDay)
        .orderBy('order')
        .all()
      return {
        day: this.selectedDay,
        items: tagesordnungForSelectedDay,
      }
    },
  },
  mounted() {
    this.fetchTagesordnung()
  },
  methods: {
    toLocaleDateString,
    toWeekday,
    fetchTagesordnung() {
      TagesordnungItem.fetchAllByMeeting(this.meeting)
    },
    agendaItemLink(tagesordnungItem) {
      if (tagesordnungItem.agenda_item.is_paragraph) {
        return
      }
      return {
        name: 'agendaitem',
        params: { id: tagesordnungItem.agenda_item.id },
        query: { tagesordnungitem_id: tagesordnungItem.id },
      }
    },
    getSelectedDay() {
      const today = new Date()
      let date
      if (today < this.meeting.start || this.meeting.concluded < today) {
        date = this.meeting.start
      } else {
        date = today
      }
      this.selectedDay = date.toISOString().split('T')[0]
      return this.selectedDay
    },
    responsible(item) {
      if (item.agenda_item) {
        if (item.agenda_item.business) {
          if (item.agenda_item.business.responsible) {
            return item.agenda_item.business.responsible.title
          }
        }
      }
      return '-'
    },
  },
}
</script>
<style>
.select-day-button {
  border-left-width: 1px !important;
}
</style>
<style scoped>
.scroll-container {
  overflow-x: auto;
}
</style>
