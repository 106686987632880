<template>
  <Sidebar>
    <DividedSidebarList
      v-if="hasContent"
      :title="$t('Back_to_committee')"
      :subtitle="committee.title ? parent === committee : null"
      :parent="parent"
      empty-message=""
      :items="committees"
    />
  </Sidebar>
</template>
<script>
import mixin from '@/components/sidebars/mixin'
import Committee from '@/store/models/committee'
import { mapState } from 'vuex'

export default {
  name: 'BusinessSidebar',
  mixins: [mixin],
  props: {
    committeeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      committees: [],
      committee: null,
    }
  },
  computed: {
    ...mapState('application', {
      parent: (state) => {
        return { url: state.currentFullPath } | this.committee
      },
    }),
    hasContent() {
      return this.committees.length && this.committee
    },
  },
  mounted() {
    this.fetchCommittees()
    this.fetchParent()
  },
  methods: {
    async fetchCommittees() {
      this.committees = await Committee.fetchAll()
    },
    async fetchParent() {
      this.committee = await Committee.findOrFetch(this.committeeId)
    },
  },
}
</script>
